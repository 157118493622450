import "./App.css";
import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CircularLoad from "./components/common/CircularLoad";

function App() {
  const Auth = lazy(() => import("./components/Auth/Auth"));

  return (
    <div className="App">
      <Routes>
        <Route
          path="auth"
          element={
            <Suspense fallback={<CircularLoad />}>
              <Auth />
            </Suspense>
          }
        >
          <Route path="*" element={<>Page not found</>} />
        </Route>
        <Route index element={<Navigate to={"auth"} />} />
        <Route path="*" element={<Navigate to={"auth"} />} />
      </Routes>
    </div>
  );
}

export default App;
