export const ErrorIcon = (
  <svg
    fill="#a24444"
    viewBox="0 0 64 64"
    data-name="Material Expand"
    id="Material_Expand"
    // xmlns="http://www.w3.org/2000/svg"
    stroke="#a24444"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <rect height="12" width="4" x="15" y="11"></rect>
      <rect height="4" width="4" x="15" y="27"></rect>
      <path d="M56,2H8A6.006,6.006,0,0,0,2,8V42a6.006,6.006,0,0,0,6,6H21.764l-3,6H14a2,2,0,0,0-2,2v6h4V58H48v4h4V56a2,2,0,0,0-2-2H45.236l-3-6H56a6.006,6.006,0,0,0,6-6V8A6.006,6.006,0,0,0,56,2ZM8,6H56a2,2,0,0,1,2,2V36H6V8A2,2,0,0,1,8,6ZM40.764,54H23.236l3-6H37.764ZM56,44H8a2,2,0,0,1-2-2V40H58v2A2,2,0,0,1,56,44Z"></path>
      <rect height="4" width="22" x="32" y="11"></rect>
      <rect height="4" width="26" x="28" y="19"></rect>
      <rect height="4" width="22" x="28" y="27"></rect>
    </g>
  </svg>
);
