import { Box } from "@mui/material";
import React from "react";
import { ErrorIcon } from "../../assets/SVGs/ErrorIcon";

export default function Error({ message, setError, ...props }) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Box>
        {ErrorIcon}
        <h2>Something went wrong</h2>
        <p>{message}</p>
        <button
          className="btn--primary"
          style={{ width: "100%" }}
          onClick={() => {
            setError("");
            window.location.reload();
          }}
        >
          Back to Login
        </button>
      </Box>
    </Box>
  );
}
