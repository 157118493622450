import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
// import { generateToken, decryptToken } from "./functions";
import Error from "../components/common/Error";
import { Alert, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";

const DIContext = createContext();

const SnackbarComponent = React.memo(({ open, message, onClose, status }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      sx={{
        marginBottom: "50px",
      }}
    >
      <Alert
        onClose={onClose}
        severity={status}
        sx={{
          width: {
            xs: "60%",
            md: "90%",
          },
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
});

export const DIProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showToast, setShowToast] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [error, setError] = useState("");
  const nav = useNavigate();
  const environment = useMemo(() => {
    return {
      REACT_APP_BASE_URL: process.env.REACT_APP_BASE_URL,
      REACT_APP_REPORTING_APP_URL: process.env.REACT_APP_REPORTING_APP_URL,
      REACT_APP_TRADING_APP_URL: process.env.REACT_APP_TRADING_APP_URL,
      REACT_APP_CRYPTO_KEY: process.env.REACT_APP_CRYPTO_KEY,
      // PORT: process.env.PORT,
    };
  }, []);

  const login = (token) => {
    localStorage.setItem("auth", token);
    setIsAuthenticated(true);
  };
  const logout = () => {
    localStorage.removeItem("auth");
    setIsAuthenticated(false);
  };
  // function isTokenValid(expiryTime) {
  //   const currentTime = Math.floor(Date.now() / 1000);
  //   return currentTime < expiryTime;
  // }
  const showToastMessage = useCallback((message, status) => {
    setShowToast(message);
    setToastStatus(status);
  }, []);

  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      setIsAuthenticated,
      login,
      logout,
      environment,
      nav,
      showToastMessage,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setIsAuthenticated, isAuthenticated]
  );

  return error === "" ? (
    <>
      <DIContext.Provider value={contextValue}>{children}</DIContext.Provider>
      <SnackbarComponent
        open={showToast && showToast !== ""}
        message={showToast}
        onClose={() => {
          setShowToast("");
          setToastStatus("");
        }}
        status={toastStatus}
      />
    </>
  ) : (
    <Error message={error} setError={setError} />
  );
};

export const useDI = () => useContext(DIContext);
