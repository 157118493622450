import actions from './actions';

const initState = {
  authMessage: null,
  authError: null,
  loading:false,
  loginToken :null,
};


export default function authReducer(state = initState, action) {
  switch (action.type) {

    case actions.LOGIN:
      return {
        ...state,
        loading: true,
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loginToken: action.payload.token,
        authMessage: 'Login success',
      };
    case actions.LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        authError: action.payload.error,
      };
    case actions.SIGNUP:
      return {
        ...state,
        loading: true,
      };
    case actions.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        authMessage: action.payload.message,
      };
    case actions.SIGNUP_FAILED:
      return {
        ...state,
        loading: false,
        authError: action.payload.error,
      };
    

    case actions.AUTH_RESET:
      return {
        ...state,
        loginLoading: false,
        loading: false,
        loginMessage: null,
        loginError: null,
      };

    default:
      return state;
  }

}
