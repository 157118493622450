const actions = {
  AUTH_RESET: 'AUTH_RESET',
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',
  SIGNUP: 'SIGNUP',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAILED: 'SIGNUP_FAILED',


  login: (data) => ({
    type: actions.LOGIN,
    payload: { data },
  }),
  loginSuccess: (token) => ({
    type: actions.LOGIN_SUCCESS,
    payload: { token },
  }),
  loginFailed: (error) => ({
    type: actions.LOGIN_FAILED,
    payload: { error },
  }),
  signup: (data) => ({
    type: actions.SIGNUP,
    payload: { data },
  }),
  signupSuccess: (message) => ({
    type: actions.SIGNUP_SUCCESS,
    payload: { message },
  }),
  signupFailed: (error) => ({
    type: actions.SIGNUP_FAILED,
    payload: { error },
  }),

  authReset: () => ({
    type: actions.AUTH_RESET,
  }),
};

export default actions;
